body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaderboard-tab {
  background: rgba(21, 21, 21, 1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}

.score-value {
  font-size: 16px;
  color: gold;
  font-weight: bold;
}

.reward-text {
  font-size: 14px;
  color: #686d72;
}

.rank-number {
  font-size: 18px;
  color: white;
  font-weight: bold;
}
