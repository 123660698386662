.slot-machine h1 {
  font-size: 36px;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.slot-machine .slots {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.slot-machine .slot {
  font-size: 45px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background-color: #333;
  border: 4px solid #fff;
  border-radius: 10px;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  color: #fff;
}

.slot-machine .slot:hover {
  transform: scale(1.1);
}
/* 
.slot-machine button {
  font-size: 20px;
  font-weight: bold;
 
  border: none;
  border-radius: 5px;
  cursor: pointer;
 
  transition: background-color 0.3s ease;
}
.slot-machine h2 {
  color: #df2727;
  font-size: 20px;
} */

/* .slot-machine button:hover {
  background-color: #ff8533;
} */
/* 
 */
.fruit-cards-toastify .Toastify__close-button > svg {
  height: 43px !important;
}
.slot-machine #result {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.slot-machine #coins {
  font-size: 20px;
  margin-top: 10px;
}
.slot-machine .fruitcard-left-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
}
.fruitcard-left-icon {
  animation: zoom-in-out 1s infinite ease-in-out;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
