@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);

/* body{
  line-height:2.2;
} */
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  /* background-color: #000000; */
}

@media (max-width: 991px) {
  .wall-of-fame-01 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .goat-full-box-02 {
    background-color: #000000;
  }

  .goat-full-box-01 {
    border-radius: 10px;
  }

  .goat-top-img-01 {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .goat-pic-01 {
    width: 100%;
    height: 100%;
  }

  .goat-ctn-box-01 {
    width: 92%;
    margin: 0px auto;
    position: relative;
    top: -120px;
  }

  .goat-num-800 {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.25;
  }

  .goat-whl-pts-num {
    background-color: rgba(21, 21, 21, 1);
    border-radius: 10px;
  }

  .gots-dollar-time {
    font-size: 13px;
    color: grey;
  }

  .goat-connect-wallet-btn {
    width: 95%;
    font-size: 14px;
    border: none;
    color: rgba(9, 128, 248, 1);
    background-color: rgba(20, 40, 61, 1);
    border-radius: 10px;
  }

  .goat-wallet-img {
    width: 20px;
    height: 20px;
  }

  .goatnum-03 {
    border: none;
    border-radius: 10px;
    background-color: rgba(20, 40, 61, 1);
    color: rgba(9, 128, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .goat-cards-01 {
    width: 100%;
    /* margin: 0px auto; */
    margin: 3px auto;
    position: relative;
    /* top:-100px; */
  }

  .goats-fortune-fetch-img-00 {
    width: 100%;
    height: auto;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 12px;
    object-fit: cover;
  }

  .goats-fortune-fetch-img-01 {
    width: 100%;
    /* height: 140px; */
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }

  .goats-whl-card {
    border-radius: 15px;
    background-color: rgba(21, 21, 21, 1);
  }

  .goat-clr-wallet {
    width: 50px;
    height: 50px;
  }

  .goat-check-01 {
    width: 58px;
    height: 35px;
    background-color: rgba(48, 209, 88, 1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .goat-cross-over-01 {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 30px;
    bottom: 15px;
  }

  .goat-footer-full-sec {
    display: flex;
    justify-content: space-around;
  }

  .goat-footer-full-sec li {
    text-decoration: none;
    list-style: none;
  }

  .goat-tot-footer-ctn {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 100px;
    background-color: black;
  }

  .goat-participate-btns {
    width: 100%;
    background-color: #007aff;
    color: white;
    border: none;
    border-radius: 10px;
  }

  .goat-participant-box {
    border: 1px solid grey;
    border-radius: 10px;
    margin-right: 10px;
  }

  .goat-footer-icons {
    width: 40px;
    height: auto;
    fill: #0d6efd;
  }
  .goat-footer-icons-01 {
    width: 30px;
    height: auto;
    fill: grey;
  }

  .goat-secondtabs-00 {
    background-color: black;
  }

  .goat-secondtabs-01 {
    background-color: black;
  }

  .goat-codemychain-ctn {
    border-radius: 15px;
  }

  .goat-us-dollars:nth-child(1) {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 15px;
    margin-left: 10px;
    background: gold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goat-us-dollars-01 {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 15px;
    margin-left: 10px;
    background: silver;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goat-us-dollars-02 {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 15px;
    margin-left: 10px;
    background: #cd7f32;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goat-title-name {
    font-size: 22px;
    color: white;
  }

  .goat-ranking-box-1 {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
  }

  .goat-timing-ctn {
    font-size: 16px;
  }

  .goat-new-numbers {
    padding-right: 20px;
    color: white;
  }

  .goat-combine-hands {
    width: 150px;
    height: auto;
    margin: 0px auto;
  }

  .whl-goat-combine-hands {
    background-color: #000000;
  }

  .goat-combine-hands-ctn {
    font-size: 18px;
  }

  .goat-copy-link-btn {
    width: 48%;
    /* height: 70px; */
    background-color: #007aff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
  }

  .goat-copy-btns {
    color: white;
  }
  /* <===========> */
  /* .mission-alt-img-tabs{
  width:50px;
  height:auto;
  margin-right: 15px;
  position: relative;
  border-radius: 50%;
} */
  /* <===========> */
  .alt-goat-tabs {
    width: 40px;
    height: auto;
    margin-right: 15px;
    object-fit: cover;
    position: relative;
    border-radius: 50%;
  }

  .alt-propelex-images {
    width: 60px;
    height: auto;
    /* object-fit: cover; */
    /* background-color: red; */
    margin-right: 9px;
    position: relative;
    margin-left: -6px;
    /* border-radius: 50%; */
  }

  .goats-earn-01 {
    font-size: 16px;
  }

  .goats-do-btn {
    background-color: #007aff;
    padding: 10px 18px;
    border: 0;
    border-radius: 10px;
    color: white;
  }

  .goat-boxex-tab {
    border-radius: 10px;
  }

  .goat-special-mission {
    color: rgba(255, 255, 255, 0.6);
  }

  .goat-clock-img {
    width: 20px;
    height: 20px;
  }

  .goat-posts {
    border-radius: 20px;
  }

  .goat-news-22 {
    position: relative;
    margin-top: -19px;
    text-align: end;
    margin-right: 11px;
    font-size: 16px;
  }

  .goat-tele-img {
    color: #2481cc;
  }

  .goat-twit-img {
    color: white;
    font-size: 13px;
  }

  .goat-wcoin-head {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }

  .goat-home-wallet {
    fill: rgba(9, 128, 248, 1);
  }
  .goat-secondtabs-00 {
    background-color: black;
    padding-bottom: 100px;
  }

  .goat-secondtabs-01 {
    padding-bottom: 100px;
  }

  .goat-full-home {
    padding-bottom: 100px;
  }

  .nav-item .nav-link .active {
    background-color: red !important;
    color: white !important;
  }

  .goat-jackpots,
  .goat-slots {
    width: 50%;
    /* background-color: gold; */
    border-radius: 10px;
  }

  .goat-jackpot-emoji {
    width: 60px;
    height: 60px;
  }

  .goat-whl-pts-num {
    margin-bottom: -100px !important;
  }

  .goat-swiper-slides-01 {
    height: 200px !important;
  }

  .goat-ranking-whl .nav-item {
    position: relative !important;
    top: 10px !important;
  }

  .goat-ranking-whl .nav-pills .nav-link.active {
    background-color: grey !important;

    padding: 10px 25px;
    /* border-bottom: 2px solid white !important; */
    border-radius: 30px;
  }

  .goat-cross-over-02 {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 30px;
    /* bottom: 0px; */
  }

  .diamong-game {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .goat-swiper-image {
    height: 90px;
  }

  .goat-play-button {
    padding: 8px 40px;
    background-color: gold;
    color: black;
    font-size: 15px;
    border-radius: 10px;
  }

  .goastflips {
    width: 50px;
    height: 50px;
  }

  .goastflips {
    width: 50px;
    height: 50px;
  }

  .home-goastflips {
    position: absolute;
    top: 8%;
    left: 12%;
  }

  .total-swiper-box .swiper-pagination {
    position: absolute;
    background-color: rgba(191, 191, 191, 0.44);
    width: 30% !important;
    bottom: 5px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    border-radius: 20px;
    padding-bottom: 2px;
  }

  .total-swiper-box .goat-swiper-slides {
    height: 240px !important;
    z-index: 2 !important;
  }

  .total-swiper-box .swiper-pagination-bullet-active {
    background-color: black !important;
  }

  #ton-connect-button {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .go3572451881 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }

  #ton-connect-button .button {
    width: 100%;
    background-color: rgba(20, 40, 61, 1) !important;
  }

  .total-swiper-box {
    z-index: 1 !important;
  }

  .goat-tot-footer-ctn {
    z-index: 2 !important;
  }

  .goat-connect-wallet button {
    width: 90% !important;
    margin: 0px auto;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba(20, 40, 61, 1);
  }

  .whl-jackpot {
    background-color: black;
  }

  .jackpot-points-table {
    color: gold !important;
    font-size: 25px;
  }

  .jackpot-numbers {
    background-color: gold;
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

  .jack-num-8 {
    background-color: #000000;
    color: white;
  }

  .lucky-lottery-01 {
    font-size: 20px;
  }

  .pointing-section-01 {
    height: 500px;
  }

  .pointing-section-01 {
    padding-bottom: 60px;
  }

  ::-webkit-scrollbar {
    overflow-y: scroll !important;
  }

  .jackpot-tokens {
    width: 50px;
    height: 50px;
    background-color: black;
    border-radius: 50%;
    color: white;
  }
  .jackpot-tokens {
    list-style: none;
  }
  .row {
    margin: 0px !important;
  }

  .container {
    padding: 0px !important;
  }
  .last-one-ctn {
    width: 100%;
    background: red;
    border-radius: 15px;
  }
  .jackpot-first-box {
    width: 80px;
    border-radius: 5px;
    margin-bottom: 2px;
    background-color: #393d40;
    border: 0;
  }
  .jackpot-first-box-01 {
    width: 100px;
    border-radius: 5px;
    background-color: #393d40;
    border: 0;
  }
  .jackpot-second-box {
    width: 80%;
    border-radius: 5px;
    border: 0;
    background-color: #393d40;
  }
  .jackpot-second-box-01 {
    background-color: #393d40;
  }
  .jackpot-second-box-01 {
    border-radius: 5px;
  }
  .jackpot-rounded {
    position: relative;
    /* border-left:1px solid red; */
    /* margin-left:-80px; */
  }
  /* .whole-jackpot-box{
  height:350px;

  overflow-y: scroll;
} */
  .jackpot-rounded::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    top: -23px;
    left: -30px;
  }
  .jackpot-rounded::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    bottom: -23px;
    left: -30px;
  }
  .loa-gif-img {
    width: 30px;
    height: 30px;
  }
  .get-more-buttons {
    background-color: gold;
    color: black !important;
    padding: 10px 25px;
    border: 0;
    border-radius: 10px;
  }
  .jackpot-tickets {
    width: 30px;
    height: 30px;
  }
  .previous-winner-boxex {
    position: relative;
    margin-top: -15px !important;
    background-color: #000000 !important;
    border-top: 1px solid grey;
  }
  .goats-get-ticket {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(40% - 30px);
  }
  .new-token-tickets-01 {
    padding: 10px;
    background-color: rgba(255, 69, 58, 1);
    color: white;
    padding: 8px 20px;
    border: 0;
    border-radius: 10px;
  }
  .jackpot-rounded-top {
    position: relative;
  }
  /* .jackpot-rounded-top
{
 border-right:1px solid green;
} */
  .jackpot-rounded-top {
    position: relative;
  }
  .jackpot-rounded-top::before {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    right: 18px;
    top: 0;
    border-right: 1px dotted black;
  }
  .whl-jackpot {
    margin-bottom: 100px !important;
  }
  .previous-token-box {
    height: 400px !important;
  }
  .previous-token-box {
    width: 95%;
    margin: 0px auto 150px auto !important;
    border-radius: 30px;
  }
  .previous-us-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgb(254, 99, 120);
    font-size: 20px;
  }
  .go-back-btn {
    border-radius: 15px;
    border: 0;
    outline: none;
    color: white;
    font-size: 20px;
  }
  .previous-congratulation {
    font-size: 25px;
  }
  .previous-points-001 {
    font-size: 40px;
  }
  .get-more-timer {
    width: 70px;
    height: 50px;
    border: 0;
    border-radius: 15px;
    background-color: rgba(21, 21, 21, 1);
    color: white;
  }
  .whole-get-more {
    background-color: #000000;
  }
  .get-more-goats {
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
  }
  .transaction-rounded-img {
    width: 50px;
    height: 50px;
    margin: 10px auto;
  }
  .goat-prices-box {
    border-radius: 15px;
  }
  .goat-prices-box-01 {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .transaction-heading {
    font-size: 14px;
  }
  .whole-get-more {
    padding-bottom: 100px;
  }
  .whl-price-box {
    background-color: rgba(48, 209, 88, 1) !important;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .calm-button-01 {
    background-color: #000000;
  }
  .calm-icons {
    width: 20px;
    height: 20px;
  }
  .keyboard-arrows {
    width: 90%;
    height: 30px;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%);
    position: absolute;
    background-color: rgba(10, 132, 255, 0.15);
    /* border:1px solid #e2e8f0; */
  }

  .keyboard-arrows-sec {
    width: 20%;
    height: 30px;
    background-color: rgba(10, 132, 255, 0.15);
    bottom: -20px;
    z-index: 1;
    transform: rotate(90deg);
    position: absolute;
    right: 0px;
  }
  .moderate .keyboard-arrows-sec {
    width: 20%;
    height: 30px;
    background-color: rgba(10, 132, 255, 0.15);
    bottom: -20px;
    z-index: 1;
    transform: rotate(90deg);
    position: absolute;
    left: 0px;
  }
  .go-lock-btn {
    border-radius: 10px !important;
  }
  .keyboard-arrow-right {
    color: #007aff;
  }

  .keyboard-arrows-001 {
    position: absolute;
    width: 13%;
    height: 30px;
    bottom: 24%;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
    background-color: rgba(10, 132, 255, 0.15);
    overflow: hidden;
    z-index: 1;
  }
  .home-us-btns {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(182, 91, 172);
  }
  .home-us-btns-01 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .whl-home-us-btns {
    position: absolute;
    width: 100%;
    top: 10px;
  }
  .daily-check-in-btn {
    background: rgba(0, 0, 0, 0.6);
    border: 0;
    border-radius: 25px;
  }
  .daily-check-in-ctn {
    font-size: 14px;
    font-weight: 400;
  }
  .shopping-cart-01 {
    width: 20px;
    height: 20px;
  }
  .red-line-convertor {
    transform: rotate(90deg);
    color: #ffffff;
    font-size: 14px;
  }
  .ranking-grade-points {
    border-radius: 10px;
    background: radial-gradient(circle at bottom right, #fedb37, #8a6e2f),
      radial-gradient(circle at top left, #ffffff, #5d4a1f) !important;
  }
  .ranking-copper-medal {
    background: linear-gradient(to right, #4a231a, #94716b) !important;
    border-radius: 10px;
  }

  /*  */
  @keyframes moveRight {
    0% {
      transform: translateX(-100%);
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 1;
    }
  }
  .keyboard-arrow-right {
    animation: moveRight 2s linear infinite;
  }
  .relative-container {
    position: relative;
  }

  .offcanvas {
    position: relative;
    z-index: 2;
  }

  .confetti-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  .total-jackpot-box {
    height: 300px;
    overflow-y: scroll;
  }

  .jackpot-input-boxex {
    animation: inputbox 2s ease-in-out infinite;
    -webkit-animation: inputbox 2s ease-in-out infinite;
  }
  .goat-light-lottery {
    width: 100%;
    height: 100px;
  }
  .lottery-tokens-01 {
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: -70px;
    margin-bottom: 30px;
  }
  .alter-points-tokens {
    font-size: 30px;
    color: gold;
  }
  .alter-jackpot {
    font-size: 16px;
  }
  .goats-timer-sec {
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .whl-jackpot .paper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1;
  }
  .models-go-back-btn {
    border-radius: 10px;
    font-size: 16px;
  }
  .modal-header {
    border-bottom: none !important;
  }
  .get-tickets {
    width: 80%;
    height: 90px;
    /* background-color: red; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .get-tickets-btn-01 {
    border: none;
    border-radius: 10px;
  }
  .goat-jackpot-emoji-01 {
    height: 60px;
    width: 60px;
    padding: 10px 10px;
    background-color: gold;
    border-radius: 10px;
  }
  .goat-jackpot-emoji-001 {
    height: 60px;
    width: 60px;
    background-color: gold;
    border-radius: 10px;
  }
  .alter-lottery-slots {
    font-size: 13px;
  }
  .goat-jackpot-calendar {
    height: 50px;
    width: 50px;
    padding: 10px;
    background-color: #007aff;
    border-radius: 10px;
  }
  .bani-loader-ctns-01 {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes inputbox {
    0% {
      background-color: rgba(163, 163, 163, 0.1);
    }
    50% {
      background-color: rgba(163, 163, 163, 0.3);
    }
    100% {
      background-color: rgba(163, 163, 163, 0.1);
    }
  }

  @-webkit-keyframes inputbox {
    0% {
      background-color: rgba(163, 163, 163, 0.1);
    }
    50% {
      background-color: rgba(163, 163, 163, 0.3);
    }
    100% {
      background-color: rgba(163, 163, 163, 0.1);
    }
  }

  .modal-dialog-bottom {
    position: fixed;
    bottom: 110px;
    margin: 0;
    width: 100%;
    transition: transform 0.3s ease-out;
  }

  .modal.fade .modal-dialog-bottom {
    transform: translateY(100%);
  }

  .modal.show .modal-dialog-bottom {
    transform: translateY(0);
  }
  .modal-content {
    border-radius: 30px;
  }
  .modal-dialog-bottom {
    background-color: none !important;
  }
  .jackpot-us-dollars {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px auto;
    background: rgb(178, 201, 70);
    color: grey;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .jackpot-dollars-ctn {
    font-size: 18px;
  }
  .jackpot-dollars-pointind {
    font-size: 35px !important;
    color: gold;
  }
  .get-more-spins {
    border: 0;
    border-radius: 10px;
  }
  .new-slot-coins-01 {
    width: 40px;
    height: 40px;
  }
  .full-slots-icons {
    margin-top: -75px;
  }
  .goats-score-board {
    height: 200px;
    border: 2px solid grey;
    border-radius: 10px;
  }
  .new-score-board {
    position: relative;
  }
  .new-score-board::before {
    content: '';
    width: 80px;
    height: 1px;
    background-color: grey;
    position: absolute;
    left: -5px;
    bottom: -10px;
    border-radius: 10px;
  }
  .new-number-board {
    position: relative;
  }
  .new-number-board::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    left: -3px;
    bottom: -10px;
    background-color: grey;
    border-radius: 10px;
  }
  .goats-spin-btns {
    width: 100%;
    margin: 0px auto;
    border: none;
    border-radius: 10px;
  }
  .mini-games-lotteryone {
    width: 100%;
    height: auto;
  }
  /* <===============> */
  .total-small-size-ctn {
    font-size: 13px;
  }
  .goat-total-new-heading {
    font-size: 14px !important;
  }
  .goat-total-sub-heading {
    font-size: 13px !important;
  }
  .total-jackpot-box {
    height: 250px;
    overflow-y: scroll;

    margin-right: 5px;
    scrollbar-color: grey;
    scrollbar-width: inherit;
  }
  .total-jackpot-box::-webkit-scrollbar {
    width: 12px;
  }
  .total-jackpot-box::-webkit-scrollbar-track {
    background: white;
    border-radius: 10px;
  }
  .total-jackpot-box::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
    width: 12px;
    height: 50px;
  }
  .nnn {
    border-top: 1px solid grey;
  }
  .OutlineCancel-area {
    width: 35px;
    height: 35px;
    color: grey;
  }
  .hub-head-ctn {
    font-size: 45px;
    color: white;
  }
  .goats-lottery-tkt {
    width: 70px;
    height: 70px;
  }
  .coin-flipping-img {
    width: 80%;
    height: 200px;
    margin: 0px auto;
  }
  .flipping-nav-items {
    width: 50%;
  }
  .flipping-nav-items-btn {
    width: 100%;
    border-radius: 10px;
    color: white;
  }
  .flipping-nav-pills {
    background-color: grey;
    border-radius: 10px;
  }
  .goat-flipping .nav-pills .nav-link.active {
    background-color: gold;
    color: black;
  }
  .thoushand-img-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: black;
  }
  .second-flipping-pills {
    border-radius: 10px;
  }
  .tn-coins {
    color: rgba(255, 69, 58, 1);
  }
  .second-goat-flipping .nav-pills .nav-link.active {
    background-color: rgba(255, 69, 58, 1);
    color: black !important;
  }
  .Frontimage-01 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .friends-zone {
    padding-bottom: 100px;
  }
  /* .bani-close-items{
    border-top-right-radius:10px;
    border-top-left-radius:10px;
  } */
  /* <==========GAME============> */
  .bani-jackpot-child {
    border-radius: 20px;
    background-color: red;
  }
  .whole-bin-childs {
    background-color: red;
  }
  .top-first-left-btn {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #212529;
    /* border-bottom:1px solid black!important; */
  }
  .top-first-right-btn {
    border-top-right-radius: 10px;
    background-color: #212529;
    border-bottom: 1px solid black !important;
  }
  .bin-tot-img-boxex {
    background-color: #222121;
  }
  .new-bin-box-btn {
    background-color: #212529;
    border-bottom: 1px solid black !important;
  }
  .box-def-01 {
    background-color: RGB(52, 58, 64);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .total-claimed-amt {
    background: #9e9e9e4d;
    color: white;
    border: none;
    border-radius: 7px;
  }
  /* background: #9e9e9e4d;
    color: white;
    border: none;
    border-radius: 7px; */

  /* .active-btn{
    background-color: #007aff;
  } */
  /* .active-image{
    background-color: white;
  } */
  .table-bin-points {
    background-color: rgba(20, 40, 61, 1);
    color: rgba(9, 128, 248, 1);
  }
  .table-bin-points-001 {
    background-color: rgb(19 49 8);
    color: #50e63f;
    width: 80%;
    margin: 10px auto;
    border-radius: 8px;
  }
  .table-bin-points {
    width: 80%;
    margin: 10px auto;
    border-radius: 8px;
  }
  .full-black-cover {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    background-color: black;
    opacity: 0.4;
  }
  .bani-new-play-btn {
    padding: 5px 30px;
    background-color: green;
    color: white;
  }
  .mini-ninja-bani {
    position: absolute;
    top: 0;
  }
  .jackpot-new-modals-sec table {
    background-color: #dbdbdb;
    width: 100%;
    border-radius: 10px;
  }
  .jackpot-new-modals-sec table th,
  td {
    padding: 15px;
  }
  .Goldentoken-img {
    width: 30px;
    height: 30px;
  }
  .slot-modals-table table {
    background-color: #dbdbdb;
    width: 100%;
    border-radius: 10px;
  }
  .slot-modals-table table th,
  td {
    padding: 15px;
  }
  .fruitscard-modals-table table {
    background-color: #dbdbdb;
    width: 100%;
    border-radius: 10px;
  }
  .fruitscard-modals-table table th,
  td {
    padding: 15px;
  }

  .fruit {
    position: absolute;
    width: 100px;
    transition: transform 0.5s ease-in;
  }

  .fruit.sliced {
    transform: rotate(45deg);
    opacity: 0;
  }

  .slice-effect {
    font-size: 24px;
    color: red;
    position: absolute;
    top: 0;
    left: 50%;
  }
  .goat-pic-001 {
    width: 40px;
    height: auto;
    object-fit: cover;
    margin-right: 5px;
  }
  .initials-fallback-01 {
    width: 40px;
    height: 40px;
    background-color: gold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    margin-right: 15px;
  }
  .initials-fallback-02 {
    width: 40px;
    height: 40px;
    background-color: silver;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    margin-right: 15px;
  }
  .initials-fallback-03 {
    width: 40px;
    height: 40px;
    background-color: #cd7f32;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    margin-right: 15px;
  }
  .goat-check-01-1 {
    padding: 15px 10px;
    background-color: #297efa;
    border-radius: 5px;
  }
  .input-box-code {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .err-first-ctn {
    width: 50%;
    margin: 5px auto 20px;
    padding: 10px;
    background-color: #676363;
    border-radius: 20px;
  }
  .max-limit-01 {
    font-size: 18px;
    color: red;
  }
  .posts-devopos-001 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .locker-image {
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    bottom: 0px;
    background-color: black;
    opacity: 0.8;
  }
  .coming-soon-patience {
    width: 60px;
    height: auto;
    animation: jump 1s ease-in-out infinite;
  }
  .bani-loader-img {
    width: 60px;
    height: auto;
    animation: loader 2s infinite;
  }
  .fruitcard-play-btn {
    padding: 10px 30px;
    background-color: #067b33;
    font-size: 20px;
    border-radius: 8px;
    border: 0px;
    color: white;
  }
  .number-codes-01 {
    background-color: #3c4342;
    padding: 10px;
    font-size: 25px;
    border-radius: 6px;
    position: relative;

    top: 10px;
  }
  .slots-machines-timing {
    font-size: 35px;
  }
  .rewards-hours {
    background-color: #333;
    padding: 5px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
  }
  .jackpot-rewards-hours {
    background-color: #333;
    /* padding:6px 6px; */
    width: 80px;
    height: auto;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    font-size: 24px;
  }
  .jackpot-rewards-hours-01 {
    font-size: 14px;
  }
  /* .day-one-bani{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
} */
  .total-claimed-points {
    color: #6b7478;
    font-size: 30px;
  }
  .total-claimed {
    font-size: 25px;
  }
  .points-section {
    margin-top: 15px;
  }
  /* #52b853e0 */
  /* .daily-reward-timer {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
} */
  /* @keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

  .coin-img-points {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 15px;
    animation: rotate 2s linear infinite; /* Adjust duration (2s) as needed */
  }
  /* .daily-reward-timer{
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
} */
  @keyframes loader {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); /* Zoom in */
    }
    100% {
      transform: scale(1); /* Zoom out */
    }
  }

  @keyframes jump {
    0%,
    100% {
      transform: translateY(0); /* Ground position */
    }
    50% {
      transform: translateY(-30px); /* Jump height */
    }
  }
  .dot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    /* opacity: 0; */
    animation: fade 2s infinite;
  }
  .new-slots-game-code {
    background: radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%);
  }
  /* Keyframes for the dot animation */
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* Different animation delays for each dot */
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  /* <===================> */
}
@media only screen and (min-width: 320px) and (max-width: 658px) {
  .get-more-buttons {
    padding: 5px !important;
  }
  .keyboard-arrows-sec {
    width: 22%;
  }
  .moderate .keyboard-arrows-sec {
    width: 22%;
  }
  .transaction-heading {
    font-size: 12px;
  }
  .goats-earn-01 {
    font-size: 14px;
  }
}
/* General styling for fruit */
.fruit {
  position: absolute;
  width: 12vw; /* Adjusted to viewport width for responsiveness */
  transition: transform 0.5s ease-in;
}

/* When fruit is sliced */
.fruit.sliced {
  transform: rotate(45deg);
  opacity: 0;
}

/* Slicing effect */
.slice-effect {
  font-size: 5vw; /* Font size responsive to viewport width */
  color: red;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); /* Centering slice effect */
}
.new-partner-tokens {
  border-radius: 10px;
}
.jackpot-page-notification {
  position: absolute;
  right: 17px;
  top: 35px;
}
.jackpot-new-modals-sec .Toastify__progress-bar {
  background-color: #3498db !important;
}
.jackpot-new-modals-sec .Toastify__toast-icon {
  color: #3498db;
}
.jackpot-new-modals-sec .Toastify__close-button {
  color: grey !important;
}
.bani-points-plus {
  color: grey !important;
}
/* #pills-home-goats-tab{
  color:grey!important;
}
#pills-profile-partners-tab{
  color:grey!important;
} */
.goat-ranking-whl-alter .nav-pills .nav-link.active {
  color: white !important;
}
/* position: absolute;
    right: 15px;
    top: 35px; */

/* Media query for smaller mobile screens */
@media only screen and (max-width: 600px) {
  .fruit {
    width: 18vw; /* Slightly larger for smaller screens */
  }

  .slice-effect {
    font-size: 6vw; /* Larger text on small screens */
  }
}

/* Media query for landscape mode */
@media only screen and (orientation: landscape) {
  .fruit {
    width: 10vh;
  }

  .slice-effect {
    font-size: 4vh;
  }
}

/* 2048 Game specific styles */
.game-board {
  background: rgba(21, 21, 21, 1);
  border-radius: 10px;
  padding: 8px;
  margin: 0 auto;
  max-width: 400px; /* Limit maximum width */
  aspect-ratio: 1; /* Make it square */
  touch-action: none;
  user-select: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.game-board .row {
  display: flex;
  justify-content: space-between;
  height: 25%; /* Each row takes 1/4 of the container */
  padding: 2px;
}

.game-board .row:last-child {
  margin-bottom: 0;
}
.cell {
  flex: 1;
  margin: 2px;
  aspect-ratio: 1;
  background: rgba(48, 48, 48, 1);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(14px, 4vw, 20px);
  font-weight: bold;
  color: white;
  transition: all 0.15s ease;
}

/* Game status styling */
.game-status {
  background: rgba(21, 21, 21, 1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.score {
  font-size: clamp(18px, 5vw, 24px);
  font-weight: bold;
  color: #007aff;
}

.high-score,
.plays {
  font-size: 14px;
  opacity: 0.8;
}

/* Tile colors with better contrast */
.value-2 {
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-4 {
  background: linear-gradient(135deg, #ff8008, #ffa931);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-8 {
  background: linear-gradient(135deg, #ffd200, #f7971e);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-16 {
  background: linear-gradient(135deg, #11998e, #38ef7d);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-32 {
  background: linear-gradient(135deg, #4e65ff, #92effd);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-64 {
  background: linear-gradient(135deg, #a770ef, #cf8bf3);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.value-128 {
  background: linear-gradient(135deg, #ff0844, #ffb199);
  color: white;
  font-size: clamp(14px, 3.5vw, 22px);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(255, 8, 68, 0.3);
}

.value-256 {
  background: linear-gradient(135deg, #f6d242, #ff52e5);
  color: white;
  font-size: clamp(14px, 3.5vw, 22px);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(246, 210, 66, 0.3);
}

.value-512 {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: clamp(14px, 3.5vw, 22px);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 198, 255, 0.3);
}

.value-1024 {
  background: linear-gradient(135deg, #ff512f, #dd2476);
  color: white;
  font-size: clamp(12px, 3vw, 20px);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(255, 81, 47, 0.4);
}

.value-2048 {
  background: linear-gradient(135deg, #ffd700, #ff0080);
  color: white;
  font-size: clamp(12px, 3vw, 20px);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow:
      0 0 10px rgba(255, 215, 0, 0.5),
      0 0 20px rgba(255, 215, 0, 0.3),
      0 0 30px rgba(255, 0, 128, 0.3);
  }
  to {
    box-shadow:
      0 0 20px rgba(255, 215, 0, 0.7),
      0 0 30px rgba(255, 215, 0, 0.5),
      0 0 40px rgba(255, 0, 128, 0.5);
  }
}

/* Button styling */
.bani-new-play-btn {
  background: #007aff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  font-weight: bold;
  transition: all 0.2s ease;
  width: 100%;
  font-size: 16px;
}

.bani-new-play-btn:disabled {
  background: rgba(48, 48, 48, 1);
  color: rgba(255, 255, 255, 0.5);
}

/* Responsive layout */
@media (max-width: 480px) {
  .game-board {
    width: 95vw; /* Use viewport width */
    max-width: 350px; /* Limit size on small screens */
    padding: 6px;
  }

  .cell {
    margin: 2px;
    font-size: clamp(12px, 3.5vw, 18px);
  }

  .game-status {
    padding: 12px;
  }
}

@media (max-width: 360px) {
  .game-board {
    max-width: 300px;
    padding: 4px;
  }

  .cell {
    margin: 1px;
    font-size: clamp(10px, 3vw, 16px);
  }

  .score {
    font-size: 18px;
  }
}

/* Container modifications */
.container {
  padding: 0 15px !important;
  max-width: 500px;
  margin: 0 auto;
}

/* Toast modifications */
.Toastify__toast-container {
  padding: 0;
  width: auto;
  max-width: 90%;
  margin: 0 auto;
}

.Toastify__toast {
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Game over text */
.max-limit-01 {
  color: rgba(255, 69, 58, 1);
  font-size: 16px;
  padding: 15px;
  background: rgba(21, 21, 21, 1);
  border-radius: 10px;
  margin-top: 20px;
}

/* Animation for new tiles */
@keyframes pop {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.cell:not(:empty) {
  animation: pop-and-glow 0.25s ease-in-out;
}

@keyframes pop-and-glow {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  }
}

.cell:not(:empty):hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
.ninja-game-container {
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: #000;
  z-index: 9999;
}

.ninja-game-container canvas {
  width: 100%;
  height: 100%;
  display: block;
}
