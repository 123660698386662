/* body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
    font-family: Arial, sans-serif;
  }
   */
@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.Righteous-font {
  font-family: 'Righteous', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: grey;
  text-align: center;
}

.goat-full-box-02 {
  background-color: #000000;
}

.game-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100vh;
  border: 1px solid #ccc;
  background: url(https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/wood-bg2.jpg);
  overflow: hidden;
}

.scoreboard {
  font-size: 24px;
  margin: 10px;
}

.stars {
  position: absolute;
  top: 10px;
  right: 10px;
}

.fruit {
  position: absolute;
  font-size: 40px;
  cursor: pointer;
  transition: transform 0.1s;
}

.fruit:hover {
  transform: scale(1.1);
  /* Slight zoom effect */
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: red;
  z-index: 10;
  /* Ensure it is on top */
}

.game-restart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.restart-btn {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #35af00;
  font-size: 20px;
  border: 1px solid #35af00;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  animation: zoom 1s infinite;
}

.game-over-01 {
  width: 260px;
  height: auto;
  border-radius: 10px;
  animation: zoom-in 1s ease-in-out forwards;
}

.cancel-icons {
  width: 50px;
  height: 50px;
}

.fruit {
  position: absolute;
  font-size: 50px;
  pointer-events: auto;
}

.start-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.start-btn {
  font-size: 20px;
  background-color: #28a745;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  border-radius: 5px;
}

.start-btn:hover {
  background-color: #218838;
}

.initials-fallback {
  width: 40px;
  height: 40px;
  background-color: #c135dcb8;
  /* Gray background */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* Makes it a circle */
  font-weight: bold;
  font-size: 18px;
  /* Adjust size as needed */
  margin-right: 15px;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.coin {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: transform 1s;
}

.coin.flipping {
  transform: rotateY(360deg);
  /* Flip animation */
}

button {
  /* margin-top: 20px; */
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
}

.head-flipping-coins {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.tail-flipping-coins {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.head-flipping-coins img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.tail-flipping-coins img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.head-ctns {
  position: absolute;
  bottom: -80%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  background-color: rgba(21, 9, 9, 1);
  border-radius: 5px;
}

.flipping-coin-btn {
  background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  border: 0;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px 1px 2px,
    rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px,
    rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px,
    rgba(0, 0, 0, 0.07) 0px 32px 64px;
  color: white;
  font-size: 22px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.flipping-coin-btn:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.back-btns {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 22px;
  background-color: #35af00;
  border-radius: 5px;
  border: 0;
  /* transform-style: preserve-3d; */
}

.goat-bitcoin-banner {
  /* background-image: url("../images/tailflip7.jpg"); */
  background-size: 100% 100%;
  background-position: top -10%;
  background-repeat: no-repeat;
}

/* Container for flipping animation */
.coin-flip-area {
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  /* This gives the 3D perspective effect */
}

/* Flipping animation */
.flipping-animation img {
  animation: flip 5s infinite;
  /* Rotate for 1 second */
  transform-style: preserve-3d;
}

.square-left-icons {
  font-size: 50px;
}

.coin-flip-area {
  perspective: 1000px;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(1000deg);
  }

  50% {
    transform: rotateY(1009deg);
  }

  75% {
    transform: rotateY(1018deg);
  }

  100% {
    transform: rotateY(1027deg);
  }
}

/* <=========================> */
/* Base styles for larger screens */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #111;
  color: #00ffcc;
  font-family: 'Courier New', Courier, monospace;
  text-shadow:
    0 0 10px #00ffcc,
    0 0 20px #00ffcc,
    0 0 30px #00ffcc;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

#score {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

canvas {
  background-color: #000;
  border: 2px solid #00ffcc;
  /* box-shadow: 0 0 20px #00ffcc, 0 0 30px #ff00ff, 0 0 40px #00ffcc, 0 0 50px #ff00ff; */
}

#restartButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00ffcc;
  color: #111;
  border: none;
  font-size: 1.2rem;
  font-family: 'Courier New', Courier, monospace;
  cursor: pointer;
  box-shadow:
    0 0 10px #00ffcc,
    0 0 20px #00ffcc;
  transition: transform 0.2s;
}

.snake-home-arrow {
  font-size: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
}

#restartButton:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  #score {
    font-size: 1.2rem;
  }

  canvas {
    width: 90vw;
    height: 70vw;
    /* border-radius:20px; */
    border-width: 1px;
    /* box-shadow: 0 0 15px #00ffcc, 0 0 25px #ff00ff; */
  }

  #restartButton {
    font-size: 1rem;
    padding: 8px 20px;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  #score {
    font-size: 1rem;
  }

  canvas {
    width: 85vw;
    height: 85vw;
    /* box-shadow: 0 0 10px #00ffcc, 0 0 20px #ff00ff;  */
  }

  #restartButton {
    font-size: 0.9rem;
    padding: 7px 14px;
  }
}

.direction-buttons {
  display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-template-rows: repeat(3, 50px);
  gap: 5px;
  margin-top: 2px;
}

.direction-buttons button {
  color: #111;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  text-shadow: 0 0 10px #00ffcc;
  background-color: transparent;
  padding: 0px;
  font-size: 40px;
  border-radius: 6px;
}

.direction-buttons button:hover {
  transform: scale(1.1);
}

/* Empty cells in the grid for the arrow layout */
.direction-buttons button:nth-child(1) {
  grid-column: 2 / 3;
}

.direction-buttons button:nth-child(2) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.direction-buttons button:nth-child(3) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.direction-buttons button:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

/* background-color: #00ffcc;
  box-shadow: 0 0 20px #00ffcc; */

/* <============================> */
.top-fruit-game-sec {
  height: 100vh;
  background-image: url(https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/bg3%20-%20Copy.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Knewave', system-ui;
}

#container {
  width: 750px;
  height: 600px;
  margin: 0px auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

#front {
  font-size: 40px;
  color: #d3901d;
  width: 650px;
  height: 450px;
  padding: 10px;
  margin: 30px auto 20px auto;
  z-index: 2;
  display: none;
}

#front img {
  width: 280px;
}

#instructions {
  width: 450px;
  height: 50px;
  margin-top: 40px;
  font-size: 30px;

  background-color: #d3901d;
  color: #2e1d11;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 0px 0px #775012;
}

#fruitcontainer {
  background: url(https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/wood-bg2.jpg);
  background-size: cover;
  width: 650px;
  height: 450px;
  padding: 10px;
  margin: 30px auto 20px auto;
  background-color: white;
  color: black;
  text-align: center;
  font-family: cursive, sans-serif;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 4px 0px 0px #4b4b4e;
  position: relative;
}

.fruit {
  position: absolute;
  width: 50px;
  cursor: pointer;
}

#score {
  display: flex;
}

.scoreDisplay {
  z-index: 1;
  display: flex;
  background-color: transparent;
  color: #888e61;
  position: absolute;
  font-size: 30px;
  justify-items: center;
}

#score img {
  width: 40px;
  align-items: center;
  padding-right: 5px;
}

#trialsleft {
  margin-top: 7px;
  display: flex;
  position: absolute;
  right: 0px;
  background-color: transparent;
  z-index: 1;
}

.life {
  width: 30px;
  padding-right: 5px;
}

#startReset {
  position: relative;
  width: 200px;
  padding: 10px;
  margin: 0 auto;

  cursor: pointer;
  text-align: center;
  background-color: #8d8315;
  box-shadow: 0px 4px 0px 0px #5c5619;
  border-radius: 5px;
  transition: all 0.2s;
}

#startReset:active {
  background-color: #69620c;
  box-shadow: 0px 0px #5c5619;
  top: 4px;
  color: white;
}

#gameOver {
  box-sizing: border-box;
  width: 500px;
  height: 300px;
  background: transparent;
  color: #d3901d;
  text-transform: upperCase;
  text-align: center;
  font-size: 3em;
  position: absolute;
  top: 170px;
  left: 150px;
  z-index: 2;
}

#readyMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d3901d;
  font-size: 2em;
  text-align: center;
  z-index: 100;
  /* Make sure it's above other elements */
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  #container {
    width: 95%;
    height: 70%;
    margin: 0px auto;
    padding: 0px;
  }

  #instructions {
    width: 100%;
  }

  #fruitcontainer {
    width: 100%;
    height: 380px;
    padding: 0px;
  }

  #gameOver {
    width: 100%;
    left: 0px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #trialsleft {
    right: 0px;
    left: auto;
  }

  .gaming-action-msg {
    font-size: 14px;
  }

  .new-fruits-01 {
    font-size: 10px;
  }

  #fruit1 {
    font-size: 10px !important;
  }

  .coinflipping-left-icons {
    position: absolute;
    top: -65px;
    right: 10px;
    font-size: 50px;
  }
}

.snake-home-arrow-01 {
  animation: zoom-in-out 1s infinite ease-in-out;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Zoom in */
  }

  100% {
    transform: scale(1);
    /* Zoom out */
  }
}

.coinflipping-left-icons {
  animation: zoom-in-out 1s infinite ease-in-out;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.btn-close-neww {
  filter: invert(1);
  opacity: 1;
  box-shadow: none !important;
}
